export const translationPT = {
  "back": "Voltar",
  "balance": "Equilibrio",
  "cancel": "Cancelar",
  "clear": "Limpar",
  "combinations": "Combinações",
  "confirm": "Confirmar",
  "draw": "Sorteio",
  "draws": "Sorteios",
  "gameMode": "Modo de jogo",
  "number": "Número",
  "numbers": "Números",
  "others": "Outros",
  "rules": "Regras",
  "stake": "Estaca",
  "stakes": "Estacas",
  "tax": "Imposto",
  "tickets": "Ingressos",
  "ticket": "Ingresso",
  "understood": "Já sei",
  "wallet": "Carteira",

  "Basket": {
    "basket": "Cesta",
    "add_bet": "Adicionar uma aposta",
    "insufficient_credits": "A sua aposta no {{N}} é muito elevada em comparação com o seu saldo",
    "remove_item_confirm": "Confirmar exclusão da aposta ?",
    "total_of": "Total do",
    "validate": "Validar o meu cesto",
    "empty_basket": "Carrinho vazio,\nque tal apostar?",
  },

  "Booking": {
    "bet_creation1": "Criando",
    "bet_creation2": " a sua aposta",
  },

  "Bet": {
    "active_permutation": "Permutação ativa",
    "details": "Detalhes da aposta",
    "max_gain_gross": "Ganho bruto máximo",
    "max_gain_net": "Ganho líquido máximo",
    "max_gain": "Ganho máximo",
  },

  "Exit": {
    "leaveTheGame": "Está prestes a abandonar a aplicação {{game}}.",
    "leave": "Sair do jogo",
  },

  "Error": {
    "back_to_draws": "Voltar aos sorteios",
    "draw_bet_closed": "O sorteio não pode mais ser jogado, por favor elimine-a",
    "not_enough_draws": "O número de sorteios escolhidos excede o número de sorteios disponíveis",
    "pageReload": "Recarregar página",
    "reloadGame": "reabra o jogo",
    "seeMyBets": "Veja minhas apostas",
    "session_list_empty_1": "Nenhum sorteio",
    "session_list_empty_2": "disponível de momento",
    "session_expired_1": "O seu sorteio",
    "session_expired_2": "não está disponível para seleção",
    "technicalError": "Erro técnico",
    "contact_support": "contacte o suporte",
  },

  "GameRules": {
    "game_rules": "Regras do jogo",
    "max_gain": "Ganho máximo",
    "rules": "Regras",
    "what_is_it": "O que é ?",
    "enter_an_amount": "Introduza um valor"
  },

  "Grid": {
    "max_selections_reached": "Não é permitido selecionar mais de {{max}} números",
    "permutate": "Trocar",
    "permutation_enabled": "Permutação ativa",
    "permutation_entering": "Está prestes a introduzir uma permutação",
    "permutation_help_1": "Jogar com <[permutação]> significa escolher mais números do que o número definido para o tipo de aposta (Simples).",
    "permutation_help_2": "Se um jogador escolher <[X]> números em permutação, então está a jogar o equivalente a <[N]> jogos simples correspondentes a <[N]> combinações simples.",
    "permutation_mincount_1": "Ativar a troca de",
    "permutation_mincount_2": "números",
    "select_your_numbers_1": "Selecionar os seus ",
    "select_your_numbers_2": "números",
    "validate_selection": "Validar minha seleção",
    "delete_grid": "Limpar a grelha"
  },

  "MyBets": {
    "pending": "Em curso",
    "past": "Passado",
    "filter": "Filtrado",
  },

  "Session": {
    "welcome": "Bem-vindo ao jogo ",
    "select_game_session": "Escolha uma sessão de jogo",
    "select_game_sessions": "Escolhendo sessões de jogo",
    "validate_selection": "Validar minha seleção",
    "today": "Hoje",
    "tomorrow": "Amanhã"
  },

  "Stake": {
    "error_amount_too_high": "Montante demasiado elevado, não pode introduzir mais de {{N}} {{currency}}.",
    "error_amount_too_low": "Montante demasiado baixo, não pode introduzir menos de {{N}} {{currency}}.",
    "error_amount_staggering": "Apenas os intervalos de {{N}} são válidos, por favor volte a introduzir o montante correto.",
    "error_total_price_exceeded": "O preço total do carrinho é muito alto (máx. {{N}}).",    
    "title1": "Valor da ",
    "title2": "aposta",
    "personalized_amount": "Valor personalizado",
    "unit_price": "Preço unitário",
    "unit_ticket_price": "Preço unitário do bilhete",
    "reset_grid": "Você está prestes a apagar sua grade, tem certeza?",
    "ticket_price": "Preço do bilhete",
    "max_price": "Prémio máximo",
    "validate_amount": "Validar o valor",
    "validate_stake": "Validar a estaca",
    "max_draws": "Sorteios máximas"
  },

  "Ticket": {
    "draw_results": "Resultados do sorteio",
    "wait": "Sorteios a seguir",
    "won": "Ganho",
    "lost": "Perdido",
    "canceled": "Cancelado",
    "totalCost": "Custo total",
    "refTicket": "Ref do bilhete",
    "contact_support": "Contactar o suporte",
    "win_payment_blocked_error": "Estamos a ter um erro técnico no pagamento dos seus ganhos",
    "canceled_payment_blocked_error": "Estamos a ter um erro técnico no pagamento dos seus reembolsos",
    "empty_tickets": "Não tens apostas? Vamos começar a criar algumas juntos",
    "email": "Mail",
    "n_transaction": "N° da transação",
    "bet": "Aposta"
  }
}