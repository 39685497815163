import { IPlayerBetsRequest } from 'src/models/ticketing/ticket.model';
import { ENV } from '../../env';
import kp_axios from './api.koralplay.service';

//=============================================================================
// API CALLS
//=============================================================================

const TICKETING_URL = ENV.API_URL + '/ticketing-reading/rest';
const ENGINE_URL = ENV.API_URL + ENV.ENGINE_PATH;

export const getPlayerBets = async (params: IPlayerBetsRequest) => {
  return kp_axios.get(`${TICKETING_URL}/v1/online-player/bets`, { params });
}

export const getDrawResults = async (gameId: number, drawIdList: string[]) => {
  //TODO: api multi-tirage inexistante - en attendant, appel des api mono-tirages
  if (parseInt("1") == 1) {
    return Promise.all(
      drawIdList.map(drawId => {
        return kp_axios
          .get(`${ENGINE_URL}/v2/webplayer/games/${gameId}/lottery/instant/draws/${drawId}`)
          .then(res => res.data)
      }))
      .then(retList => ({data: retList}))
  }
  const body = {
    drawIdList
  }
  return kp_axios.patch(`${ENGINE_URL}/v2-a/webplayer/draws/resultsFromIDList`, body);
}