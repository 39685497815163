import { Box, Tabs, Tab, useTheme, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyle } from './myBets.style';
import { RootState } from "src/store/root.reducer";
import SVGFilterTickets from "src/assets/icons/SVGFilterTickets";
import SVGEmptyBasket from "src/assets/icons/SVGEmptyBasket";
// Actions
import * as ticketActions from 'src/store/actions/ticket.actions';
import { loadTaxes } from "src/store/actions/taxes.actions";
// Component
import BetItem from "src/component/ticketing/myBets/betItem.comp";
import dayjs from "dayjs";

const MyBetsPage = () => {

  const TAB_PENDING = 0, TAB_PAST = 1;

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>()

  //====================================== States

  const betList = useSelector((state: RootState) => state.tickets.betList);

  const [currentTab, setCurrentTab] = useState(TAB_PENDING);
  const tabList = ['MyBets.pending', 'MyBets.past'];

  //====================================== Effects

  useEffect(() => {
    dispatch(loadTaxes());
  }, [])

  useEffect(() => {
    const waitingBets = currentTab === TAB_PENDING;
    const periodInDays = waitingBets ? 7 : 60; //TODO: get it from config, default 60d
    const createdAfter = dayjs().add(-periodInDays, 'day').toISOString();
    dispatch(ticketActions.getPlayerBets(waitingBets, createdAfter));
  }, [currentTab])

  //====================================== Functions

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  //====================================== Render

  return (
    <Box sx={style.betsContainer}>
      <Tabs sx={style.tabs} value={currentTab} onChange={handleTabChange}>
        {tabList.map((tabKey, index) => (
          <Tab
            key={index}
            sx={[style.tab, currentTab === index && style.tabSelected]}
            disableRipple
            label={<Typography sx={[style.tabText, currentTab === index && style.tabTextSelected]}>{t(tabKey)}</Typography>}
          />
        ))}
      </Tabs>

      {currentTab === TAB_PAST && betList.length !== 0 && 
        <Box sx={style.boxFilter}>
          <SVGFilterTickets />
          <Typography sx={style.boxFilterTxt}>{t('MyBets.filter')}</Typography>
        </Box>
      }

      <Box sx={style.listContainer}>
        {betList.length === 0 && 
          <Box sx={style.ticketsEmpty}>
            <SVGEmptyBasket />
            <Typography sx={style.ticketsEmptyTxt}>{t('Ticket.empty_tickets')}</Typography>
          </Box>
        }
        {betList?.map((bet, index) => (
            <BetItem key={index} item={bet} index={index} />
        ))}
      </Box>
    </Box>
  )
}

export default MyBetsPage;