import commonStyle from "src/common/style/commonStyle";
import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  betsContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '148px',
      paddingRight: '148px'
    }
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
      margin: '16px 24px',
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-start',
        margin: '32px 24px',
        gap: "24px"
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiButtonBase-root': {
      borderRadius: '16px',
      flex: 1,
      [theme.breakpoints.up('lg')]: {
        minWidth: '124px',
        flex: 'none'
      },
    },
  },
  ticketsEmpty: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px'
  },
  ticketsEmptyTxt: {
    marginTop: '16px',
    fontSize: 16,
    fontWeight: 400,
    color: "#BBBBBB",
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  tab: {
  },
  tabSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  tabText: {
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'none',
    color: '#BBBBBB',
  },
  tabTextSelected: {
    color: '#FFF'
  },
  listContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.up('lg')]: {
      ...commonStyle.slimScrollBar(theme),
      paddingRight: '50px',
      overflowY: "auto",
      maxHeight: "860px",
    },
  },
  boxFilter: {
    margin: '12px 24px',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },
  boxFilterTxt: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000'
  }
})