import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    width: '100%'
  },
  subContainer: {
    minHeight: 0,
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      marginTop: '56px',
      height: 'auto',
      justifyContent: 'space-around',
      flexDirection: 'row-reverse'
    }
  },
  gameDescription: {
    padding: '16px',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    marginBottom: '8px',
    whiteSpace: 'pre-wrap',
    boxShadow: '0px 6px 12px 0px #00000014',
    "&:hover, &:active": {
      backgroundColor: '#FFF',
    },
  },
  groupArrowIconOpen: {
    transform: 'rotate(180deg)',
  },
  customAmountInput: {
    width: "calc(100%)",
    marginBottom: '24px',
    "& .MuiInputBase-root": {
      borderRadius: '8px',
      height: '44px',
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: '#1D1D1D'
      }
    }
  },
  payLineBox: {
    borderRadius: '24px',
    height: '64px',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '40px',
    paddingRight: '16px',
    boxShadow: ' 0px 8px 8px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.03)'
  },
  boxCircle: {
    display: 'flex',
    flexDirection: 'row'
  },
  circle: {
    width: '24px',
    height: '24px',
    backgroundColor: "#FFF",
    borderRadius: '50px',
    marginLeft: '-8px'
  },
  gainValue: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "700",
  },
  blockLeft: {
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      minHeight: 0, //scroll
      marginRight: '130px',
      maxWidth: '515px'
    }
  },
  blockRight: {
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      minHeight: 0, //scroll
      marginLeft: '130px',
      maxWidth: '515px'
    }
  },
  customAmountBtn: {
    backgroundColor: '#1D1D1D',
    '&:hover': { backgroundColor: '#1D1D1D' },
    '&:disabled': { backgroundColor: '#1D1D1D' },
    height: '48px',
    width: '100%',
    marginTop: '8px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',    
    "& fieldset": {
      border: 'none',
    },
    '& input': {
      color: '#FFF',
      fontWeight: 700,
      textTransform: 'initial',
      fontSize: '16px',
      marginLeft: '8px',
      textAlign: 'center',
      MozAppearance: 'textField',
      WebkitAppearance: 'textField',
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'textField',
      },
      "&::placeholder": {
        color: '#FFF',
        fontWeight: 700,
        fontSize: '16px',
        opacity: 1,
      },
      "&:focus::placeholder": {
        fontWeight: 400,
        opacity: 0.5,
      },
    }
  },
  actionButtonText: {
    color: '#FFF',
    fontWeight: 700,
    textTransform: 'initial',
    fontSize: '16px',
    marginLeft: '8px',
  },
})