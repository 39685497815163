import { Box, useTheme, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { RootState } from '../../../store/root.reducer';
import { makeStyle } from './bookingSubmit.style';
import Lottie from 'react-lottie';
import * as BasicLoto from '../../../assets/animated_icons/basic_loto.json';
import SVGReqPayment from 'src/assets/icons/SVGReqPayment';
import { routePath } from 'src/routes';
import * as BasketActions from 'src/store/actions/basket.actions';
import { EBookingStatus } from 'src/models/ticketing/booking.model';
import * as PlayerActions from 'src/store/actions/player.actions';
import * as BookingActions from 'src/store/actions/booking.actions';
import { EErrorType } from 'src/models/core/app.model';

const BookingSubmitPage = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BasicLoto,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>()

  const bookingStore = useSelector((state: RootState) => state.booking)
  const bookingStatus = useSelector((state: RootState) => state.booking.bookingStatus);
  const isBookingFetching = useSelector((state: RootState) => state.booking.isFetching);
  const isBookingError = useSelector((state: RootState) => state.booking.error);

  //====================================== Effects

  useEffect(() => {
    if (!isBookingFetching && !isBookingError && bookingStatus === EBookingStatus.BOOKING_SUCCESS) {
      dispatch(BasketActions.clear_basket());
      navigate(routePath.myBets);
      dispatch(BookingActions.set_clear_status());
      dispatch(PlayerActions.getPlayerInfo());
    }
    if (!isBookingFetching && (isBookingError || bookingStatus === EBookingStatus.BUY_FAILED || bookingStatus === EBookingStatus.GAME_CONTROL_FAILED)) {
      navigate(routePath.error + '?errorType=' + EErrorType.booking_failed)
    }
  }, [bookingStore])

  //====================================== Functions


  //====================================== Render
  return (
    <Box sx={style.container}>
      {/*
      This will be when the SVG will be animated
      <Box>
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
        />
      </Box> */}
      <SVGReqPayment />
      <Box sx={style.msgContainer}>
        <CircularProgress sx={{color: theme.palette.primary.main}} size={24}/>
        <Typography sx={style.creationText1}>
          {t('Booking.bet_creation1')}
          <Typography sx={style.creationText2} component={'label'}>
            {t('Booking.bet_creation2')}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}

export default BookingSubmitPage;