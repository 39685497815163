import * as service from "../services/ticket.service";
import { RootStateFn } from "../root.reducer";
import { timeZone } from "src/common/i18n/i18n";
import { IBetTicket, IPlayerBetsRequest } from "src/models/ticketing/ticket.model";

//--------
// Definition of the action names
export const ACTIONS = {
  //TICKETS
  TICKETS_ERROR: 'TICKETS_ERROR',
  TICKETS_FETCHING: 'TICKETS_FETCHING',
  TICKETS_FETCHING_DONE: 'TICKETS_FETCHING_DONE',
  //DRAW RESULTS
  TICKETS_DRAW_RESULTS: 'TICKETS_DRAW_RESULTS',
  TICKETS_DRAWS_RESULTS_FETCHING: 'TICKETS_DRAWS_RESULTS_FETCHING',
  TICKETS_DRAWS_RESULTS_FETCHING_DONE: 'TICKETS_DRAWS_RESULTS_FETCHING_DONE',
}

//--------
// Lifecycle
//TICKETS
export const set_state_error = (err: any) => {
  return { type: ACTIONS.TICKETS_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.TICKETS_FETCHING };
}
export const set_state_fetching_done = (r: IBetTicket[]) => {
  return { type: ACTIONS.TICKETS_FETCHING_DONE, payload: r };
}
//DRAW RESULTS
export const set_drawResults = (r) => {
  return { type: ACTIONS.TICKETS_DRAW_RESULTS, payload: r };
}
export const set_state_drawResults_fetching = () => {
  return { type: ACTIONS.TICKETS_DRAWS_RESULTS_FETCHING };
}
export const set_state_drawResults_fetching_done = () => {
  return { type: ACTIONS.TICKETS_DRAWS_RESULTS_FETCHING_DONE };
}

//--------
// Actions

export const getPlayerBets = (waitingBets: boolean, createdAfter: string, withDrawResults = true) => {
  return async (dispatch: any, getState: RootStateFn) => {
    const gameId = getState().game.gameId;
    const userId = getState().player.playerInfo.userID;
    const params: IPlayerBetsRequest = {
      waitingBets,
      userId,
      gameId,
      createdAfter,
      timeZone,
    }
    try {
      dispatch(set_state_fetching());
      const r = await service.getPlayerBets(params)
      const betList: IBetTicket[] = r.data?.content || [];
      dispatch(set_state_fetching_done(betList))
      
      if (waitingBets == false && withDrawResults) {
        const drawIdSet = new Set<string>();
        betList.forEach(bet => {
          bet.betDetails.listOfStatusAndDraws.forEach(E => {
            drawIdSet.add(E.draw.drawId);
          })
        })
        const drawIdList = Array.from(drawIdSet);
        const currentDrawIdList = getState().tickets.drawResultList.map(E => E.drawId);
        const isDrawResultListChanged = currentDrawIdList.sort().join() != drawIdList.sort().join();

        if (isDrawResultListChanged) {
          dispatch(getDrawResults(gameId, drawIdList)); //async/deferred call
        }
      }        
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

export const getDrawResults = (gameId: number, drawIdList: string[]) => {
  return async (dispatch: any) => {
    try {
      dispatch(set_state_drawResults_fetching());
      let r = await service.getDrawResults(gameId, drawIdList)
      if (r.data) {
        dispatch(set_drawResults(r.data.map(rdata => rdata.draw)))
      }
    } catch (err) {
      dispatch(set_state_drawResults_fetching_done());
    }
  }
}
