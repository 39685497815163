import { IDraw } from "../lottery/draw.model";

export enum EBetSettlementStatus {
  WAIT = "WAIT",
  WIN = "WIN",
  LOST = "LOST",
  CANCELED_GAME = "CANCELED_GAME",
  CANCELED_TICKET = "CANCELED_TICKET",
}

export enum EBetPaymentStatus {
  PAYMENT_NOT_INITIATED = "PAYMENT_NOT_INITIATED",
  PAYMENT_BLOCKED = "PAYMENT_BLOCKED",
  PAYMENT_NOT_NEEDED = "PAYMENT_NOT_NEEDED",
  PAYMENT_PENDING_PLAYER = "PAYMENT_PENDING_PLAYER",
  PAYMENT_EXPIRED = "PAYMENT_EXPIRED",
  PAYMENT_REQUESTED = "PAYMENT_REQUESTED",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
}

export interface ITicketPlayer {
  phoneNumber?: string;
  userId: string;
}

export interface IBetTicket {
  gameId: number;
  bookingId: string;
  settlementStatus: EBetSettlementStatus;
  paymentStatus: EBetPaymentStatus;
  gameBetId: string;
  price: number;
  player: ITicketPlayer;
  creationDate: string;
  expectedSettlementDate: string;
  gameTicketReference?: string;
  betDetails: IBetTicketDetails;
}

export interface IBetTicketDetails {
  betId: number;
  amtTotalWon?: number;
  gameId: number;
  gameType: string;
  creationTime: string;
  price: number;
  stake: number;
  earn: number;
  listOfBonusId: [];
  locked: false,
  taxAmount: number;
  betMode: string;
  numberOfDraws: number;
  betTypeId: number;
  listOfBetGrids: IBetTicketGrid[];
  listOfStatusAndDraws: IBetTicketStatusAndDraws[];
}

export interface IBetTicketGrid {
  gridName: string;
  listOfLottoNumbers: number[];
  usedAdditionalNumbers: number;
}

export interface IBetTicketStatusAndDraws {
  status: string;
  draw: IDraw;
  taxAmount: number;
}


export interface IPlayerBetsRequest {
  createdAfter: string;
  gameId: number;
  timeZone: string;
  userId: number;
  waitingBets: boolean;
}