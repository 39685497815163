import Box from '@mui/material/Box';
import { Typography, useTheme, Popover, Button } from '@mui/material';
import { makeStyle } from './supportPopup.style';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store/root.reducer';
import SVGInfo from 'src/assets/icons/SVGInfo';
import SVGCross from 'src/assets/icons/SVGCross';
import SVGCopy from 'src/assets/icons/SVGCopy';

type Props = {
  onClose: () => void;
  open: boolean;
  buttonText?: string;
  children?: ReactNode;
  hideBackdrop?: boolean;
  title?: string;
  bookingId?: string;
  gameTicketReference?: string;
}

function SupportPopup({
  onClose,
  open,
  buttonText,
  children,
  hideBackdrop,
  title,
  gameTicketReference,
  bookingId
}: Props): JSX.Element {

  const theme = useTheme();
  const style = makeStyle(useTheme());
  const { t } = useTranslation();
  const supportData = useSelector((state: RootState) => state.app.config.support);

  const onCopyTextValue = (value) => {
    navigator.clipboard.writeText(value)
  }

  return (
    <Popover
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop ?? true}
      disableScrollLock={true}
      anchorReference={"none"}
      sx={style.popover}
    >

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Box sx={{width: '48px', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: theme.palette.shades[50], borderRadius: '50px'}}>
          <SVGInfo fill={theme.palette.primary.main} />
        </Box>

        <Button
          disableRipple
          onClick={onClose}
          sx={{width: '48px', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '0px'}}
        >
          <SVGCross width="28" height="28" fill="#98A2B3" />
        </Button>
      </Box>

      <Box sx={style.supportPopupBox}>
        <Typography sx={style.title}>{t('Ticket.contact_support')}</Typography>

        <Typography sx={style.supportPopupTitle}>
          {t('Ticket.email')}:
        </Typography>
        <Typography sx={style.supportPopupTxt}>
          {supportData.email}
        </Typography>

        <Typography sx={style.supportPopupTitle}>
          Tel:
        </Typography>
        <Typography sx={style.supportPopupTxt}>
          {supportData.phoneNumber}
        </Typography>

        {bookingId && 
          <Box>
            <Typography sx={style.supportPopupTitle}>
              {t('Ticket.n_transaction')}:
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Typography sx={style.supportPopupTxt}>
                {bookingId}
              </Typography>
              <Button onClick={() => onCopyTextValue(bookingId)} disableRipple sx={{minWidth: '0px', padding: '0px', height: '24px'}}>
                <SVGCopy />
              </Button>
            </Box>
          </Box>
        }
        {gameTicketReference && 
          <Box>
            <Typography sx={style.supportPopupTitle}>
              {t('ticket')}:
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Typography sx={style.supportPopupTxt}>
                {gameTicketReference}
              </Typography>
              <Button onClick={() => onCopyTextValue(gameTicketReference)} disableRipple sx={{minWidth: '0px', padding: '0px', height: '24px'}}>
                <SVGCopy />
              </Button>
            </Box>
          </Box>
        }
      </Box>

      <Box sx={style.boxButton}>
        <Button
          variant="contained"
          disableRipple
          disableElevation
          sx={style.button}
          onClick={onClose}
        >
          {buttonText || 'OK'}
        </Button>
      </Box>
    </Popover>
  );
}

export default SupportPopup;
