import { makeStyle } from "./genericError.style";
import { Box, Button, Typography, useTheme } from "@mui/material";
import SVGError from "src/assets/icons/SVGError";
import { ReactNode, useState } from "react";
import SupportPopup from "src/component/core/supportPopup/supportPopup.comp";
import { useTranslation } from "react-i18next";

export type GenericErrorProps = {
  onAction?: () => void,
  actionText: string,
  title?: React.ReactNode,
  titleStrongPrefix?: ReactNode,
  children?: ReactNode,
}

export const GenericError = ({
  onAction,
  actionText,
  title,
  titleStrongPrefix,
  children,
}: GenericErrorProps) => {

  const theme = useTheme();
  const style = makeStyle(theme);
  const { t } = useTranslation();
  
  const [supportPopupOpen, setSupportPopupOpen] = useState(false);

  const onButtonAction = () => {
    onAction ? onAction() : setSupportPopupOpen(!supportPopupOpen)
  }

  return (
    <Box sx={style.container}>
      <Box sx={style.topContainer}>
        <Box sx={style.errorContainer}>
          <SVGError />
        </Box>
        <Typography sx={style.errorText}>
          <Typography sx={style.errorStrongText} component={'span'}>
            {titleStrongPrefix}
          </Typography>
          {title}
        </Typography>
      </Box>

      {children}

      <Button
        disableRipple
        disableElevation
        variant="contained"
        onClick={onButtonAction}
        sx={style.errorButton}
      >
        <Typography
          sx={style.errorButtonText}
        >
          {actionText}
        </Typography>
      </Button>
      <SupportPopup
        open={supportPopupOpen}
        onClose={onButtonAction}
        buttonText={t("understood")}
        bookingId={"AZERR4442RAZERAZER"}
      />
    </Box>
  )
}

export default GenericError;